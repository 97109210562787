<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium flex justify-between items-center w-full">
        <app-i18n code="iam.chefDetails.title"></app-i18n>
        <div class="flex gap-5">
          <button class="btn bg-theme-36 text-white sm:w-auto pb-1 pt-1" @click="$router.push(`/chefs/kitchen/${id}`)">
            {{ i18n('common.goToKitchen') }}</button><a href="javascript:;" data-toggle="modal" data-target="#rate-modal"
            class="btn bg-theme-36 text-white sm:w-auto pb-1 pt-1">
            <app-i18n code="common.rateUser"></app-i18n>
          </a>
        </div>
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div style="width: 100%; height: 30vh" class="flex justify-center" v-if="loading">
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div class="flex flex-col lg:flex-row border-b border-slate-200 border-opacity-60 pb-5 -mx-5">
          <div class="flex flex-1 px-5 items-center justify-center lg:justify-start gap-5 py-5">
            <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
              <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full" data-action="zoom" :src="record.avatar ? record.avatar : '/images/default-avatar.svg'
                " />
              <div
                class="absolute mb-1 mr-1 flex items-center text-center justify-center bottom-0 right-0 bg-theme-36 rounded-full p-2"
                v-if="record.avatar">
                <!-- <a :href="record.avatar" download="chef_avatar.jpg" -->
                <DownloadIcon class="w-4 h-4 text-white" @click="downloadPhoto(record.avatar)" />
                <!-- </a> -->
              </div>
            </div>
            <div class="flex flex-col gap-5">
              <div class="truncate sm:whitespace-normal flex items-center gap-2">
                <UserIcon class="w-5 h-5" />
                {{ record.fullName }}
              </div>
              <div class="truncate sm:whitespace-normal flex items-center gap-2 text-slate-500">
                <ClipboardIcon class="w-5 h-5" />
                {{ `${record.code || 'ــــــــــ'}` }}
              </div>
              <div class="flex items-center gap-5">
                <div class="w-10 h-10 image-fit zoom-in">
                  <Tippy :tag="isPdf(record.idCard) ? 'a' : 'img'" data-action="zoom" data-toggle="modal"
                    data-target="#viewer-modal-preview" alt="HomeFood"
                    class="flex rounded-lg border-2 border-white shadow-md tooltip" :src="presenterImgSrc(record.idCard)"
                    :content="i18n('iam.fields.idCard')" @click="setViewer(record.idCard)">
                    <img class="rounded-lg border-2 border-white shadow-md" src="@/assets/images/pdfPlaceholder.jpeg"
                      v-if="isPdf(record.idCard)" />
                  </Tippy>
                </div>
                <div class="w-10 h-10 image-fit zoom-in">
                  <Tippy :tag="isPdf(record.taxNumber) ? 'a' : 'img'" data-action="zoom" data-toggle="modal"
                    data-target="#viewer-modal-preview" alt="HomeFood"
                    class="flex rounded-lg border-2 border-white shadow-md tooltip"
                    :src="presenterImgSrc(record.taxNumber)" :content="i18n('iam.fields.taxNumber')"
                    @click="setViewer(record.taxNumber)">
                    <img class="rounded-lg border-2 border-white shadow-md" src="@/assets/images/pdfPlaceholder.jpeg"
                      v-if="isPdf(record.taxNumber)" />
                  </Tippy>
                </div>
                <div class="w-10 h-10 image-fit zoom-in">
                  <Tippy :tag="isPdf(record.freelanceCertificate) ? 'a' : 'img'" data-action="zoom" data-toggle="modal"
                    data-target="#viewer-modal-preview" alt="HomeFood"
                    class="flex rounded-lg border-2 border-white shadow-md tooltip"
                    :src="presenterImgSrc(record.freelanceCertificate)" :content="i18n('iam.fields.freelanceCertificate')"
                    @click="setViewer(record.freelanceCertificate)">
                    <img class="rounded-lg border-2 border-white shadow-md tooltip"
                      src="@/assets/images/pdfPlaceholder.jpeg" v-if="isPdf(record.freelanceCertificate)" />
                  </Tippy>
                </div>
                <div class="w-10 h-10 image-fit zoom-in">
                  <Tippy :tag="isPdf(record.medicalCertificate) ? 'a' : 'img'" data-action="zoom" data-toggle="modal"
                    data-target="#viewer-modal-preview" alt="HomeFood"
                    class="flex rounded-lg border-2 border-white shadow-md tooltip"
                    :src="presenterImgSrc(record.medicalCertificate)" :content="i18n('iam.fields.medicalCertificate')"
                    @click="setViewer(record.medicalCertificate)">
                    <img class="rounded-lg border-2 border-white shadow-md tooltip"
                      src="@/assets/images/pdfPlaceholder.jpeg" v-if="isPdf(record.medicalCertificate)" />
                  </Tippy>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-1 flex-col items-center justify-center lg:items-start gap-5 lg:mt-0 px-5 border-l border-r border-slate-200 border-opacity-60 border-t lg:border-t-0 pt-5 lg:pt-0">
            <div class="font-medium text-center" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
              {{ i18n('iam.clientDetails.contactDetails') }}
            </div>
            <address class="flex flex-col justify-center items-center lg:items-start gap-3">
              <div class="truncate sm:whitespace-normal flex items-center gap-2">
                <MailIcon class="w-4 h-4" />
                <a :href="`mailto:${record.email}`">{{
                  record.email ? record.email : 'ـــــ'
                }}</a>
              </div>
              <div class="truncate sm:whitespace-normal flex items-center gap-2">
                <PhoneIcon class="w-4 h-4" :style="isRTL
                    ? 'transform: rotateY(180deg)'
                    : 'transform: rotateY(0deg)'
                  " />
                <span style="direction: ltr !important">
                  <a :href="`tel:${record.phoneNumber}`">{{
                    record.phoneNumber ? record.phoneNumber : 'ـــــ'
                  }}</a>
                </span>
              </div>
            </address>
            <div class="font-medium text-center" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
              {{ i18n('iam.clientDetails.status') }}
            </div>
            <div class="flex flex-col justify-center items-center lg:items-start">
              <div class="truncate sm:whitespace-normal flex items-center" :class="{
                'text-theme-10 dark:text-theme-30': activated,
                'text-theme-24': !activated
              }">
                {{
                  activated ? i18n('common.activated') : i18n('common.disabled')
                }}
              </div>
            </div>
          </div>
          <div
            class="mt-6 lg:mt-0 flex-1 px-5 border-t lg:border-0 border-slate-200/60 dark:border-darkmode-400 pt-5 lg:pt-0">
            <Map v-if="record.address" class="report-maps mt-5 bg-slate-200 rounded-md" :options="mapOptions"
              :markersLocations="chefLocation" />
            <div v-else class="flex items-center justify-center h-full">
              <lottie-player src="/noLocation.json" speed="1" autoplay loop background="transparent"
                class="report-maps my-auto mx-auto w-40 h-40 rounded-md"></lottie-player>
              <!-- <img
                src="@/assets/images/noLocations.png"
                class="report-maps my-auto mx-auto w-20 h-20 bg-slate-200 rounded-md"
              /> -->
            </div>
            <div class="flex justify-end mt-2" v-if="record.address">
              <button type="button"
                class="gap-2 flex rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                style="background-color: #128c7e" @click="shareLocation">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                </svg>
                {{ i18n('common.shareLocation') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->
    <!-- BEGIN: Wallet -->
    <h2 class="text-lg font-medium mt-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
      {{ i18n('wallet.view.title') }}
    </h2>
    <div class="intro-y box rounded-xl p-5 mt-5">
      <section>
        <div class="flex justify-between items-center">
          <h6 class="caption">
            <app-i18n code="wallet.caption"></app-i18n>
          </h6>
          <a href="javascript:;" data-toggle="modal" data-target="#refund-modal"
            class="btn bg-theme-36 text-white mb-2 flex items-center">
            <app-i18n code="wallets.recharge"></app-i18n>
          </a>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-2 py-3">
          <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
            <app-wallet-card v-if="wallet && !walletLoading" :amount="wallet['balance']"
              :label="i18n('wallet.fields.totalBalance')" icon="/images/icons/ic-wallet-1.png"></app-wallet-card>
            <app-wallet-card-loading v-else />
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
            <app-wallet-card v-if="wallet && !walletLoading" :amount="wallet['actualBalance']"
              :label="i18n('wallet.fields.availableBalance')" icon="/images/icons/ic-money-5.png"></app-wallet-card>
            <app-wallet-card-loading v-else />
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
            <app-wallet-card v-if="wallet && !walletLoading" :amount="wallet['balance'] - wallet['actualBalance']"
              :label="i18n('wallet.fields.holdedBalance')" icon="/images/icons/ic-money-6.png"
              amount-style="color: #CE3131 !important;"></app-wallet-card>
            <app-wallet-card-loading v-else />
          </div>
        </div>
      </section>
    </div>
    <!-- END: Wallet -->
    <app-wallet-accounts-table user="chef" :userId="id"></app-wallet-accounts-table>
    <app-wallet-transaction-table user="chef" :userId="id"></app-wallet-transaction-table>
    <chefReviewTable user="chef" :userId="id"></chefReviewTable>
    <!-- BEGIN: Modal -->
    <viewerModal :link="viewerLink" :type="viewerType" />
    <app-refund-modal :id="id" @reset-modal="showModal = false"></app-refund-modal>
    <app-rate-modal :id="id" userType="chef" @reset-modal="showModal = false"></app-rate-modal>
    <!-- END: Modal -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WalletCard from '@/views/wallet/wallet-card.vue'
import WalletCardLoading from '@/views/wallet/wallet-card-loading.vue'
import walletTransactionTable from '@/views/wallet/wallet-transaction-table.vue'
import walletAccountsTable from '@/views/wallet/wallet-accounts-table.vue'
import chefReviewTable from './chef-review-table.vue'
import HelperClass from '@/shared/helperClass'
import Map from '@/components/map/Main.vue'
import viewerModal from '@/components/modals/viewer-modal.vue'
import refundModal from '@/components/modals/refund-modal.vue'
import rateModal from '@/components/modals/rate-modal.vue'
// import { getStorage, ref, getDownloadURL } from 'firebase/storage'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return { viewerLink: '', viewerType: null, showModal: false }
  },
  components: {
    Map,
    viewerModal,
    chefReviewTable,
    [rateModal.name]: rateModal,
    [refundModal.name]: refundModal,
    [WalletCard.name]: WalletCard,
    [WalletCardLoading.name]: WalletCardLoading,
    [walletAccountsTable.name]: walletAccountsTable,
    [walletTransactionTable.name]: walletTransactionTable
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/view/record',
      wallet: 'user/view/wallet',
      findLoading: 'user/view/loading',
      walletLoading: 'user/view/walletLoading'
    }),
    chefLocation() {
      return [
        {
          name: `${this.record?.address.name || ''}: ${this.record?.address.building
            }, ${this.record?.address.street}`,
          latitude: this.record?.address.lat,
          longitude: this.record?.address.long
        }
      ]
    },
    mapOptions() {
      return {
        location: {
          lat: this.record.address.lat,
          lng: this.record.address.long
        },
        zoom: 15
      }
    },
    activated() {
      return this.record && this.record.isEnabled
    },
    loading() {
      return this.findLoading
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.clients')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.clients')
    }
  },
  async mounted() {
    this.doReset()
    this.doSetUserType('chef')
    await this.doFind(this.id)
    await this.doFindWallet(this.id)
  },
  methods: {
    ...mapActions({
      doSetUserType: 'user/list/doSetUserType',
      doFind: 'user/view/doFind',
      doReset: 'user/view/doReset',
      doFindWallet: 'user/view/doFindWallet'
    }),
    shareLocation() {
      const { lat, long } = this.record?.address
      const message = `Check out Client location: https://maps.google.com/?q=${lat},${long}`
      window.open(
        `https://wa.me/?text=${encodeURIComponent(message)}`,
        '_blank'
      )
    },
    async downloadPhoto(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'image.jpg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    setViewer(link) {
      this.viewerLink = link
      this.viewerType = 'application/pdf'
    },
    isPdf(url) {
      return url && url.includes('pdfAttachments')
    },
    presenterImgSrc(src) {
      return HelperClass.isUrl(src)
        ? src
        : require('@/assets/images/No-Image-Placeholder.svg')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
