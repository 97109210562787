<template>
    <div>
        <div class="intro-y flex flex-col sm:flex-row mt-8">
            <h2 class="text-lg font-medium">{{ i18n('review.title') }}</h2>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-5">
            <div class="intro-y box col-span-12 lg:col-span-12 p-5">
                <!-- BEGIN: HTML Table Data -->
                <div ref="table" class="relative">
                    <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />
                    <div class="overflow-x-auto">
                        <table id="tablePrint" ref="tabulator" class="table table-report sm:my-2">
                            <thead>
                                <tr>
                                    <th class="whitespace-nowrap text-center" id="">#</th>
                                    <th v-for="col in columns" :key="col.name" :id="col.name === 'action'
                                        ? 'ignore-2'
                                        : col.name === 'avatar'
                                            ? 'ignore-3'
                                            : ''
                                        " class="text-center whitespace-nowrap">
                                        {{ i18n(col.label) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="rows.length">
                                <tr v-for="(row, index) in rows" :key="index" class="intro-x"
                                    :class="{ removedRow: row.isRemoved }">
                                    <td class="w-40">
                                        <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5 text-center">
                                            {{ rowIndex(index) }}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div class="font-medium whitespace-nowrap">
                                            {{ presenter(row, 'clientName') }}
                                        </div>
                                    </td>
                                    <td class="flex text-center justify-center">
                                        <div class="flex items-center">
                                            <svg v-for="star in 5" :key="star" aria-hidden="true"
                                                class="w-5 h-5 text-gray-300" :style="{
                                                    color:
                                                        star <= Math.round(row.rating)
                                                            ? 'gold'
                                                            : 'rgba(226, 232, 240)'
                                                }" :class="{
    'text-yellow-400': star <= Math.round(row.rating),
    'text-gray-300': star > Math.round(row.rating)
}" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <title>First star</title>
                                                <path
                                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                                </path>
                                            </svg>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div class="font-medium whitespace-nowrap">
                                            {{ presenter(row, 'review') }}
                                        </div>
                                    </td>

                                    <td class="text-center">
                                        <div class="font-medium whitespace-nowrap">
                                            {{ presenterDate(row, 'createdAt') }}
                                        </div>
                                        <div class="font-small whitespace-nowrap mt-1">
                                            {{ presenterTime(row, 'createdAt') }}
                                        </div>
                                    </td>

                                    <td>
                                        <button type="button" class="text-theme-24 font-medium" @click="showModal(row)">
                                            <Tippy tag="div" class="flex items-center gap-1 whitespace-nowrap"
                                                :content="i18n('common.delete')">
                                                <Trash2Icon class="w-4 h-4" />
                                                {{ i18n('common.delete') }}
                                            </Tippy>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <app-empty-page v-if="!rows.length && !loading" :label="i18n('empty.review')"
                            label-class="text-lg font-medium" icon-size="4x" class="box w-full py-64"></app-empty-page>
                    </div>
                    <Pagination v-if="rows.length" :page="currentPage" :pageSize="pagination.limit"
                        :rowsNumber="pagination.total" :showSizeChanger="true" :hasNext="hasNext" :hasPrev="hasPrev"
                        :loading="loading" :disabled="!rows.length" position="center" @next-page="doNextPage"
                        @prev-change="doPrevPage" @size-change="doChangePaginationPageSize" @page-change="goToPage">
                    </Pagination>
                </div>
                <!-- END: HTML Table Data -->

            </div>
        </div>
        <DeleteModal item="review" @delete="doDestroy({ chefId, id })" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionToolbar from '@/global-components/action-toolbar.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'
import { exportPrintMixin } from '@/mixins/export-print-mixin'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
    name: 'app-wallet-review-table',
    mixins: [paginationMixin, presenterMixin, exportPrintMixin],
    props: {
        userId: {
            type: String,
            required: true
        },
        user: {
            type: String,
            required: true
        },
        viewFilter: {
            type: Boolean,
            default: true
        }
    },
    components: {
        [ActionToolbar.name]: ActionToolbar,
        DeleteModal
    },
    data() {
        return {

            // data important for Mixins
            tableRef: null,
            exportTableName: 'reviews',
            excludedCols: [7],
            ignoreElements: ['ignore-1', 'ignore-2'],
            // component Data
            chefId: null,
            id: null,

            type: 'in',
            columns: [
                {
                    name: 'clientName',
                    field: 'clientName',
                    label: 'review.fields.clientName',
                    align: 'center'
                },
                {
                    name: 'rating',
                    field: 'rating',
                    label: 'review.fields.rating',
                    align: 'center'
                },
                {
                    name: 'review',
                    field: 'review',
                    label: 'review.fields.review',
                    align: 'center'
                },
                {
                    name: 'createdAt',
                    field: 'createdAt',
                    label: 'review.fields.createdAt',
                    align: 'center'
                },
                {
                    name: 'action',
                    field: 'action',
                    label: 'common.actions',
                    align: 'center'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            isRTL: 'layout/isRTL',
            language: 'layout/currentLanguageCode',
            rows: 'review/list/rows',
            hasNext: 'review/list/hasNext',
            hasPrev: 'review/list/hasPrev',
            pagination: 'review/list/pagination',
            listLoading: 'review/list/loading'
        }),
        fetchOptions() {
            return { chefId: this.userId }
        },
        loading() {
            return this.listLoading || this.exportLoading
        },
        currentPage() {
            return this.pagination.currentPage || 1
        },
        rowIndex() {
            return index => {
                return index + this.pagination.limit * (this.currentPage - 1) + 1
            }
        }
    },
    async mounted() {
        await this.doFetchFirstPage()
    },
    methods: {
        ...mapActions({
            doFetch: 'review/list/doFetch',
            doFetchNextPage: 'review/list/doFetchNextPage',
            doFetchPreviousPage: 'review/list/doFetchPreviousPage',
            doChangePaginationCurrentPage:
                'review/list/doChangePaginationCurrentPage',
            doChangePaginationPageSize: 'review/list/doChangePaginationPageSize',
            doDestroy: 'review/destroy/doDestroy'
        }),
        showModal(row) {
            console.log(row)
            this.chefId = row.chefId
            this.id = row.id
            cash('#delete-modal-preview').modal('show')
        },
        i18n(key, args) {
            return this.$t(key, args)
        }
    }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
    border-radius: 0px !important;
    box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
    border-radius: 0px !important;
    box-shadow: unset !important;
}

.pagination-ar {
    margin-right: unset !important;
    margin-left: auto !important;
}
</style>
